import { firebase_app } from "@/firebase/config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

const auth = getAuth(firebase_app);

export const AuthContext = createContext({});
export const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const router = useRouter();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (!firebaseUser) {
        if (!router.asPath.includes("/auth")) router.push("/auth/login");
      } else {
        const userData = await fetch(`/api/users/${firebaseUser.uid}`).then(
          (res) => res.json()
        );

        setUser({ ...firebaseUser, ...userData });

        if (router.asPath.includes("/auth")) router.push("/");
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
