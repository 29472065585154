import "react-contexify/dist/ReactContexify.css";
import "@/styles/globals.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css

import { Inter } from "next/font/google";

import AuthContextProvider from "@/context/AuthContext";
import ErrorBoundary from "@/components/ErrorBoundary";

const inter = Inter({ subsets: ["latin"] });

export default function App({ Component, pageProps }) {
  return (
    <div className={inter.className}>
      <ErrorBoundary>
        <AuthContextProvider>
          <Component {...pageProps} />
        </AuthContextProvider>
      </ErrorBoundary>
    </div>
  );
}
