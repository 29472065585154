import { getApps, initializeApp } from "firebase/app";

const getVar = (variable) => {
  try {
    return JSON.parse(variable);
  } catch (error) {
    return variable;
  }
};

const firebase_app = getApps().length
  ? getApps()[0]
  : initializeApp({
      apiKey: getVar(process.env.NEXT_PUBLIC_FIREBASE_API_KEY),
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    });

export { firebase_app };
