import { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, logId: null };
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI on next render
    return { hasError: true };
  }

  logErrorToMyService = async (errorInfo) => {
    console.log("LOGGING ERROR INFO", errorInfo);
    try {
      let msg = `[LOG ID: ${errorInfo.logId}] [URL: ${errorInfo.url}]:  `;

      // Include both message and stack if available
      if (errorInfo.message) msg += `${errorInfo.message}\n`;
      if (errorInfo.stack) msg += `${errorInfo.stack}\n`;
      if (errorInfo.componentStack) msg += `${errorInfo.componentStack}\n`;

      const response = await fetch("/api/logger", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(msg),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error logging to service", error);
    }
  };

  componentDidCatch(error, info) {
    // Create a log ID for this error
    const randomId = Math.random().toString(36).substring(7);

    const logId = `${randomId}-${Date.now()}`;

    // Update the state with hasError and logId
    this.setState({ hasError: true, logId });

    // Create an error info object that includes the message, stack, component stack, and logId
    const errorInfo = {
      message: error.message,
      stack: error.stack,
      componentStack: info.componentStack,
      logId,
      url:
        typeof window !== "undefined"
          ? window.location.href
          : "URL not available",
    };

    // Log the error using your logging service
    this.logErrorToMyService(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <div className="w-dvw h-dvh flex flex-col gap-8 justify-center items-center">
          <h1 className="text-2xl text-center font-semibold">
            Something went wrong.
          </h1>

          <p className="text-sm font-light">
            Log ID: <code>{this.state.logId}</code>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
